<script setup>

import {ref, watchEffect} from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useQueryLoading } from '@vue/apollo-composable';
import VueApexCharts from 'vue3-apexcharts';
import ContentBox from '@/components/ContentBox.vue';
import CustomTitle from '@/components/Title.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    currentEnvironment: {
        type: Object,
        required: true,
    },
});

const products = ['CODA', 'CARO'];
const series = ref([]);

const { refetch, onResult, onError } = useQuery(
    gql`query GetProductStates($fiduciaryId: String) {
        productStates(fiduciaryId:$fiduciaryId) {
            timestamp
            coda { active inactive }
            caro { active inactive }
        }
    }`,
    {
        fiduciaryId: props.currentEnvironment.id,
    },
);
onResult((result, context) => {
    const res = result.data.productStates;
    series.value = [
        {name: 'active', data: [res.coda.active, res.caro.active]},
        {name: 'inactive', data: [res.coda.inactive, res.caro.inactive]},
    ];
});
onError((error, context) => {
    console.log('ERROR:', error);
});
const loading = useQueryLoading();

const chartOptions = {
    chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: { show: false },
    },
    noData: {
        text: 'Loading ...',
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 5,
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff'],
    },
    colors: ['#0d52ff', '#f4f4f4'],
    grid: { show: false },
    xaxis: {
        categories: products,
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { show: false },
    },
    yaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + ' ' + t('h-clients').toLowerCase();
            },
            title: {
                formatter: function (val) {
                    return t('lbl-client-product-state-' + val);
                },
            },
        },
    },
    legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'right',
        offsetX: 40,
    },
    dataLabels: { enabledOnSeries: [0] },
};

function chartClick (event, chartContext, config) {
    if (config.dataPointIndex < 0 || config.seriesIndex < 0) {
        return;
    }
    const product = products[config.dataPointIndex];
    const tipe = series.value[config.seriesIndex].name;
    console.log('clicked on', product, 'type', tipe);
}

watchEffect(async () => {
    refetch({
        fiduciaryId: props.currentEnvironment.id,
    });
});

</script>

<template>
    <content-box>
        <CustomTitle :level='2' no-default-margins class='mb-3' :style-of='3'>
            {{ $t('ttl-client-activation-rate') }}
        </CustomTitle>
        <div v-if='loading'>
            loading ...
        </div>
        <div v-else>
            <VueApexCharts
                type='bar'
                height='150'
                :options='chartOptions'
                :series='series'
                @click='chartClick'
            />
        </div>
    </content-box>
</template>
