<script>
import Problems from '@/fiduciary/modules/Problems';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import ProductStates from '@/fiduciary/modules/ProductStates.vue';

export default {
    name: 'DashboardView',
    props: {
        currentEnvironment: {
            type: Object,
        },
        accountant: Object,
    },
    components: {
        ProductStates,
        Problems,
        CustomTitle,
        ContentBox,
    },
    data () {
        return {
            enableStats: false,
        };
    },
    mounted () {
        this.enableStats = this.$route.query.stats === 'true';
    },
};
</script>

<template>
    <div class='mb-6 dashboard'>
        <div class='mb-6'>
            <CustomTitle>Dashboard</CustomTitle>
        </div>
        <div class='grid gap-6 2xl:grid-cols-6'>
            <div class='col-span-3 xl:col-span-4' v-if='enableStats'>
                <ProductStates :current-environment='currentEnvironment' />
            </div>
            <div class='col-span-3 xl:col-span-4'>
                <Problems :current-environment='currentEnvironment' scrollable />
            </div>
            <div class='col-span-6 xl:col-span-2'>
                <content-box>
                    <CustomTitle :level='2' no-default-margins class='mb-3' :style-of='3'>
                        {{ $t('ttl-dashboard-feedback-module') }}
                    </CustomTitle>
                    <p>
                        {{ $t('p-dashboard-feedback-module') }}
                    </p>
                    <a
                        :href='$t("link-dashboard-feedback-module-href")'
                        target='_blank'
                        class='text-blue-400'
                    >
                        {{ $t('link-dashboard-feedback-module-content') }}
                    </a>
                </content-box>
            </div>
        </div>
    </div>
</template>
